<template>
  <div class="req-temp-container">
    <a @click="$router.push({ name: 'settings' })" class="goback">
      <feather-icon
        icon="ChevronLeftIcon"
        class="cursor-pointer align-bottom"
      />Back to settings
    </a>
    <br />
    <vs-card class="mt-6">
      <div slot="header">
        <h3 class="font-medium">Colours & fonts</h3>
      </div>
      <div class="lg:p-5 md:p-5 sm:p-3 p-2">
        <p class="text-base">
          You can customise the look of customer-facing pages including payment
          pages and customer portal. It is recommended you set these to match
          the style of your brand.
        </p>
      </div>

      <vs-row class="pullright">
        <div>
          <vs-button
            v-round
            class="only-border-btn reset-default mr-12"
            @click="resetAll"
          >
            <rotate-ccw-icon size="1.5x" class="custom-class"></rotate-ccw-icon
            >&nbsp;&nbsp;Reset all to default
          </vs-button>
        </div>
      </vs-row>
      <!----------text block--->
      <vs-row class="lg:p-5 md:p-5 sm:p-4 p-2">
        <vs-col vs-w="12" vs-lg="12" vs-sm="12">
          <h4 class="font-semibold dark-blue mb-5 p-3">Text</h4>
        </vs-col>
        <!----------font block--->
        <div class="colors-fonts">
          <div class="pl-5 colors-fonts-left">
            <div class="mb-4">
              <div class="my-5" style="min-width: 170px">
                <h5 class="font-normal">Font</h5>
              </div>
              <div
                class="my-5 flex flex-wrap pl-3 relative"
                style="max-width: 350px"
              >
                <div
                  class="mb-2 mr-2 font-btn"
                  v-for="font in fonts"
                  :key="font._id"
                >
                  <vs-button
                    class="btn-custom m-2"
                    :class="{
                      active: brandSettings.defaultFont == font.font,
                    }"
                    @click="changeFont(font)"
                    >{{ font.font }}</vs-button
                  >
                </div>
                <span class="reset-single" @click="resetFont">
                  <rotate-ccw-icon
                    size="1.5x"
                    class="custom-class"
                  ></rotate-ccw-icon>
                </span>
              </div>
            </div>

            <!----------heading colour block--->
            <div class="my-5" v-if="loaded">
              <vs-col class="my-5">
                <h5 class="font-normal">Headings Colour</h5>
              </vs-col>
              <vs-col
                class="my-5 flex flex-wrap w-auto pl-3 relative"
              >
                <div class="input-group color-picker" ref="defaultHeader">
                  <colorpicker
                    :color="brandSettings.defaultHeaderColor"
                    v-model="brandSettings.defaultHeaderColor"
                    :screenRef="defaultHeader"
                    @checkForm="checkForm"
                  />
                </div>
                <span class="reset-single-headcolor" @click="resetHeading">
                  <rotate-ccw-icon
                    size="1.5x"
                    class="custom-class"
                  ></rotate-ccw-icon>
                </span>
              </vs-col>
            </div>
          </div>
          <!----------//heading block--->
          <div class="preview mt-3 lg:pl-5">
            <p class="dark-blue text-base">Preview</p>
            <div class="textpreview p-5 text-left">
              <h4
                class="font-semibold dark-blue mb-3 w-full"
                :style="styleObject"
              >
                Heading text here
              </h4>
              <p :style="styleObject">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Porta
                non platea cursus est. Dui mattis metus, lacus, eget rhoncus,
                massa mattis. Tellus, maecenas orci ullamcorper massa. Enim
                gravida ipsum mauris senectus.
              </p>
            </div>
          </div>
        </div>

        <!----------//font block--->
      </vs-row>
      <!----------//text block--->

      <!----------buttons block--->
      <vs-row class="lg:p-5 md:p-5 sm:p-3 p-2">
        <vs-col vs-w="12" vs-lg="12" vs-sm="12">
          <h4 class="font-semibold dark-blue mb-5 p-3">Buttons</h4>
        </vs-col>
        <div class="colors-fonts">
          <div class="pl-5 colors-fonts-left">
            <!----------default colour block--->

            <div
              class="lg:my-8 md:my-5 sm:my-4 my-2"
              v-if="loaded"
            >
              <div class="my-5" style="min-width: 170px">
                <h5 class="font-normal w-full">Default Colour</h5>
              </div>
              <div
                class="input-group color-picker relative"
                ref="defaultButton"
              >
                <colorpicker
                  :color="brandSettings.defaultButtonColor"
                  v-model="brandSettings.defaultButtonColor"
                  :screenRef="defaultButton"
                  @checkForm="checkForm"
                />
                <span class="reset-single-headcolor" @click="resetBtnDefault">
                  <rotate-ccw-icon
                    size="1.5x"
                    class="custom-class"
                  ></rotate-ccw-icon>
                </span>
              </div>
            </div>

            <!----------hover colour block--->

            <div
              class="lg:my-8 md:my-5 sm:my-4 my-2"
              v-if="loaded"
            >
              <div class="my-5" style="min-width: 170px">
                <h5 class="font-normal w-full">Hover Colour</h5>
              </div>
              <div
                class="input-group color-picker relative"
                ref="hoverButton"
              >
                <colorpicker
                  :color="brandSettings.hoverButtonColor"
                  v-model="brandSettings.hoverButtonColor"
                  :screenRef="hoverButton"
                  @checkForm="checkForm"
                />
                <span class="reset-single-headcolor" @click="resetBtnHover">
                  <rotate-ccw-icon
                    size="1.5x"
                    class="custom-class"
                  ></rotate-ccw-icon>
                </span>
              </div>
            </div>

            <!----------text colour block--->

            <div
              class="lg:my-8 md:my-5 sm:my-4 my-2"
              v-if="loaded"
            >
              <div class="my-5" style="min-width: 170px">
                <h5 class="font-normal w-full">Button Text</h5>
              </div>
              <div
                class="input-group color-picker relative"
                ref="textButton"
              >
                <colorpicker
                  :color="brandSettings.textButtonColor"
                  v-model="brandSettings.textButtonColor"
                  :screenRef="textButton"
                  @checkForm="checkForm"
                />
                <span class="reset-single-headcolor" @click="resetBtnText">
                  <rotate-ccw-icon
                    size="1.5x"
                    class="custom-class"
                  ></rotate-ccw-icon>
                </span>
              </div>
            </div>
          </div>
          <!----------//preview block--->
          <div class="preview mt-3">
            <p class="dark-blue text-base">Preview</p>
            <div class="textpreview lg:p-5 md:p-5 p-2">
              <button class="btn-preview lg:mx-5 md:mx-5" :style="styleObject">
                Button Text
              </button>
              <button
                class="btn-label lg:mx-5 md:mx-5 mt-5"
                :style="styleObject"
              >
                Label
              </button>
            </div>
          </div>
        </div>
        <!----------//preview block--->
      </vs-row>
      <!----------//buttons block--->

      <!----------links block--->
      <vs-row class="lg:p-5 md:p-5 sm:p-3 p-2">
        <vs-col vs-w="12" vs-lg="12" vs-sm="12">
          <h4 class="font-semibold dark-blue mb-5 p-3">Links</h4>
        </vs-col>
        <div class="colors-fonts">
          <div class="pl-5 colors-fonts-left">
            <!----------default colour block--->

            <div
              class="lg:my-8 md:my-5 sm:my-4 my-2"
              v-if="loaded"
            >
              <div class="my-5" style="min-width: 170px">
                <h5 class="font-normal">Default Colour</h5>
              </div>
              <div
                class="input-group color-picker relative"
                ref="defaultLink"
              >
                <colorpicker
                  :color="brandSettings.defaultLinkColor"
                  v-model="brandSettings.defaultLinkColor"
                  :screenRef="defaultLink"
                  @checkForm="checkForm"
                />
                <span class="reset-single-headcolor" @click="resetLinkDefault">
                  <rotate-ccw-icon
                    size="1.5x"
                    class="custom-class"
                  ></rotate-ccw-icon>
                </span>
              </div>
            </div>
            <!----------//default colour block--->

            <!----------hover colour block--->

            <div
              class="lg:my-8 md:my-5 sm:my-4 my-2"
              v-if="loaded"
            >
              <div class="my-5" style="min-width: 170px">
                <h5 class="font-normal">Hover Colour</h5>
              </div>

              <div
                class="input-group color-picker relative"
                ref="hoverLink"
              >
                <colorpicker
                  :color="brandSettings.hoverLinkColor"
                  v-model="brandSettings.hoverLinkColor"
                  :screenRef="hoverLink"
                  @checkForm="checkForm"
                />
                <span class="reset-single-headcolor" @click="resetLinkHover">
                  <rotate-ccw-icon
                    size="1.5x"
                    class="custom-class"
                  ></rotate-ccw-icon>
                </span>
              </div>
            </div>
            <!----------//hover colour block--->
          </div>

          <!----------//preview block--->
          <vs-col vs-w="5" vs-lg="5" vs-sm="12" class="preview mt-3">
            <p class="dark-blue text-base">Preview</p>
            <vs-col
              vs-w="11"
              vs-lg="11"
              vs-sm="12"
              class="textpreview text-center p-5"
            >
              <a class="link-preview" :style="styleObject"> Link Text </a>
            </vs-col>
          </vs-col>
        </div>
        <!----------//preview block--->
      </vs-row>
      <!----------//links block--->

      <!----------feature element block--->
      <vs-row class="lg:p-5 md:p-5 sm:p-3 p-2">
        <vs-col vs-w="12" vs-lg="12" vs-sm="12">
          <h4 class="font-semibold dark-blue mb-5 p-3">Feature elements</h4>
        </vs-col>

        <div class="colors-fonts">
          <div class="pl-5 colors-fonts-left">
            <!----------fill colour block--->

            <div
              class="lg:my-8 md:my-5 sm:my-4 my-2"
              v-if="loaded"
            >
              <div class="my-5" style="min-width: 170px">
                <h5 class="font-normal">Background colour</h5>
              </div>
              <div
                class="input-group color-picker relative"
                ref="fillFeature"
              >
                <colorpicker
                  :color="brandSettings.fillFeatureColor"
                  v-model="brandSettings.fillFeatureColor"
                  :screenRef="fillFeature"
                  @checkForm="checkForm"
                />
                <span class="reset-single-headcolor" @click="resetFeatureFill">
                  <rotate-ccw-icon
                    size="1.5x"
                    class="custom-class"
                  ></rotate-ccw-icon>
                </span>
              </div>
            </div>
            <!----------//fill colour block--->

            <!----------text colour block--->

            <div
              class="lg:my-8 md:my-5 sm:my-4 my-2"
              v-if="loaded"
            >
              <div class="my-5" style="min-width: 170px">
                <h5 class="font-normal">Text colour</h5>
              </div>
              <div
                class="input-group color-picker relative"
                ref="textFeature"
              >
                <colorpicker
                  :color="brandSettings.textFeatureColor"
                  v-model="brandSettings.textFeatureColor"
                  :screenRef="textFeature"
                  @checkForm="checkForm"
                />
                <span class="reset-single-headcolor" @click="resetFeatureText">
                  <rotate-ccw-icon
                    size="1.5x"
                    class="custom-class"
                  ></rotate-ccw-icon>
                </span>
              </div>
            </div>
            <!----------//text colour block--->
          </div>

          <!----------//preview block--->
          <vs-col vs-w="5" vs-lg="5" vs-sm="6" class="preview mt-3">
            <p class="dark-blue text-base">Preview</p>
            <vs-col
              vs-w="11"
              vs-lg="11"
              vs-sm="12"
              class="textpreview text-center p-5"
            >
              <div class="feature-preview" :style="styleObject">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Porta
                non platea cursus est.
              </div>
            </vs-col>
          </vs-col>
        </div>
        <!----------//preview block--->
      </vs-row>
      <!----------//links block--->
    </vs-card>
    <div class="button-section">
      <vs-button
        :disabled="!validateForm"
        style="width: 200px; margin-right: 3rem"
        v-round
        @click="updateBrandSettings"
        >Save</vs-button
      >
      <div class="cancel-btn mid-blue" @click="onCancel"><u>Cancel</u></div>
    </div>
    <leave-popup
      :popup-active="popupActive"
      @handleClick="popActions"
    ></leave-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import colorpicker from "./ColorPicker.vue";
import { RotateCcwIcon } from "vue-feather-icons";
import LeavePopup from "@/views/components/LeavePopup";
export default {
  components: {
    colorpicker,
    RotateCcwIcon,
    LeavePopup,
  },
  data() {
    return {
      merchantId: "",
      partnerId: "",
      defaultHeader: "defaultHeader",
      defaultButton: "defaultButton",
      defaultLink: "defaultLink",
      hoverLink: "hoverLink",
      hoverButton: "hoverButton",
      textButton: "textButton",
      textFeature: "textFeature",
      fillFeature: "fillFeature",
      textButton: "textButton",
      textcolor1: "#000",
      textcolor2: "#fff",
      brandSettings: {},
      defaultSettings: {
        defaultFont: "",
        defaultFontId: "",
        defaultHeaderColor: "",
        defaultButtonColor: "",
        defaultLinkColor: "",
        hoverButtonColor: "",
        hoverLinkColor: "",
        textButtonColor: "",
        fillFeatureColor: "",
        textFeatureColor: "",
      },
      loaded: false,
      isSaved: false,
      nextObj: "", // determines the next route,
      popupActive: false, // determines if the leave popup is to be shown
      checkFormDirty: false,
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
    styleObject() {
      return {
        "--heading-color": this.brandSettings.defaultHeaderColor,
        "--default-font": this.brandSettings.defaultFont,
        "--button-color": this.brandSettings.textButtonColor,
        "--button-background-color": this.brandSettings.defaultButtonColor,
        "--button-background-color--hover": this.brandSettings.hoverButtonColor,
        "--link-color": this.brandSettings.defaultLinkColor,
        "--link-color--hover": this.brandSettings.hoverLinkColor,
        "--feature-fill-color": this.brandSettings.fillFeatureColor,
        "--feature-text-color": this.brandSettings.textFeatureColor,
      };
    },
  },
  mounted() {
    // this.defaultHeader = this.$refs['defaultHeader'];
    // this.defaultButton = this.$refs['defaultButton'];
  },
  methods: {
    ...mapActions("brandSetting", [
      "fetchDefaultBranding",
      "fetchBrandSettingsByPartner",
      "updateBrandSettingsByPartner",
    ]),
    onCancel() {
      this.$router.push({
        name: "settings",
      });
    },
    getDefaultSetting() {
      this.fetchDefaultBranding().then((res) => {
        this.fonts = res.data.data[0].fonts;
        let objIndex = this.fonts.findIndex((obj) => obj.isDefault == true);

        let fontId = this.fonts[objIndex]._id;
        let font = this.fonts[objIndex].font;
        this.defaultSettings = {
          defaultFont: font,
          defaultFontId: fontId,
          defaultHeaderColor: res.data.data[0].headingColor,
          defaultButtonColor: res.data.data[0].button.defaultColor,
          defaultLinkColor: res.data.data[0].link.defaultColor,
          hoverButtonColor: res.data.data[0].button.hoverColor,
          hoverLinkColor: res.data.data[0].link.hoverColor,
          textButtonColor: res.data.data[0].button.textColor,
          fillFeatureColor: res.data.data[0].featureElements.fillColor,
          textFeatureColor: res.data.data[0].featureElements.textColor,
        };
        this.brandSettings = {
          ...this.defaultSettings,
        };

      });
    },
    resetAll() {
      this.brandSettings = {
        ...this.defaultSettings,
      };
      this.checkFormDirty = true;
    },
    resetFont() {
      this.brandSettings.defaultFont = this.defaultSettings.defaultFont;
      this.brandSettings.defaultFontId = this.defaultSettings.defaultFontId;
      this.checkFormDirty = true;
    },
    resetHeading() {
      this.brandSettings.defaultHeaderColor =
        this.defaultSettings.defaultHeaderColor;
      this.checkFormDirty = true;
    },
    resetBtnDefault() {
      this.brandSettings.defaultButtonColor =
        this.defaultSettings.defaultButtonColor;
      this.checkFormDirty = true;
    },
    resetBtnHover() {
      this.brandSettings.hoverButtonColor =
        this.defaultSettings.hoverButtonColor;
      this.checkFormDirty = true;
    },
    resetBtnText() {
      this.brandSettings.textButtonColor = this.defaultSettings.textButtonColor;
      this.checkFormDirty = true;
    },
    resetLinkDefault() {
      this.brandSettings.defaultLinkColor =
        this.defaultSettings.defaultLinkColor;
      this.checkFormDirty = true;
    },
    resetLinkHover() {
      this.brandSettings.hoverLinkColor = this.defaultSettings.hoverLinkColor;
    },
    resetFeatureFill() {
      this.brandSettings.fillFeatureColor =
        this.defaultSettings.fillFeatureColor;
      this.checkFormDirty = true;
    },
    resetFeatureText() {
      this.brandSettings.textFeatureColor =
        this.defaultSettings.textFeatureColor;
      this.checkFormDirty = true;
    },
    getBrandSettings() {
      this.fetchBrandSettingsByPartner(this.partnerId).then((res) => {
        if (res.data.data && res.data.data != null) {
          let brandSettings = res.data.data.colorsAndFonts;
          if (brandSettings) {
            this.brandSettings = {
              defaultFont: brandSettings.text
                ? brandSettings.text.fontName
                : this.defaultSettings.defaultFont,
              defaultFontId: brandSettings.text
                ? brandSettings.text.fontId
                : this.defaultSettings.defaultFontId,
              defaultHeaderColor: brandSettings.text
                ? brandSettings.text.headingColour
                : this.defaultSettings.defaultHeaderColor,
              defaultButtonColor: brandSettings.button
                ? brandSettings.button.defaultColour
                : this.defaultSettings.defaultButtonColor,
              defaultLinkColor: brandSettings.link
                ? brandSettings.link.defaultColour
                : this.defaultSettings.defaultLinkColor,
              hoverButtonColor: brandSettings.button
                ? brandSettings.button.hoverColour
                : this.defaultSettings.hoverButtonColor,
              hoverLinkColor: brandSettings.link
                ? brandSettings.link.hoverColour
                : this.defaultSettings.hoverLinkColor,
              textButtonColor: brandSettings.button
                ? brandSettings.button.textColour
                : this.defaultSettings.textButtonColor,
              fillFeatureColor: brandSettings.featureElements
                ? brandSettings.featureElements.fillColor
                : this.defaultSettings.fillFeatureColor,
              textFeatureColor: brandSettings.featureElements
                ? brandSettings.featureElements.textColor
                : this.defaultSettings.textFeatureColor,
            };
          }
        }
        this.loaded = true;
      });
    },
    async updateBrandSettings() {
      const valid = await this.$validator.validate();

      if (!valid) {
        return false;
      } else {
        const data = {
          id: this.partnerId,
          colorsAndFonts: {
            text: {
              fontId: this.brandSettings.defaultFontId,
              fontName: this.brandSettings.defaultFont,
              headingColour: this.brandSettings.defaultHeaderColor,
            },
            button: {
              defaultColour: this.brandSettings.defaultButtonColor,
              hoverColour: this.brandSettings.hoverButtonColor,
              textColour: this.brandSettings.textButtonColor,
            },
            link: {
              defaultColour: this.brandSettings.defaultLinkColor,
              hoverColour: this.brandSettings.hoverLinkColor,
            },
            featureElements: {
              fillColor: this.brandSettings.fillFeatureColor,
              textColor: this.brandSettings.textFeatureColor,
            },
          },
        };
        this.$vs.loading();
        this.updateBrandSettingsByPartner(data)
          .then((result) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Brand Settings",
              text: result.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });
            this.isSaved = true;
          })
          .catch((err) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Brand Settings",
              text: err.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          });
      }
    },
    getPartnerId() {
      this.partnerId = JSON.parse(localStorage.getItem("user"))._id;
    },
    changeFont(e) {
      this.brandSettings.defaultFont = e.font;
      this.brandSettings.defaultFontId = e._id;
      this.checkFormDirty = true;
    },
    changeButtonText(e) {
      this.brandSettings.textButtonColor = e;
    },
    checkForm(){
      this.checkFormDirty = true;
    },
    popActions(event) {
      switch (event) {
        case "leave":
          this.nextObj();
          break;
        case "save":
          this.popupActive = false;
          // save here
          this.updateBrandSettings();
          break;
        case "cancel":
          this.popupActive = false;
          break;
        default:
          this.popupActive = false;
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    //check here
    if ((this.isFormDirty || this.checkFormDirty) && !this.isSaved) {
      this.nextObj = next;
      this.popupActive = true;
    } else {
      next();
    }
  },
  beforeMount() {
    this.merchantId =
      JSON.parse(localStorage.user).userType == "admin"
        ? JSON.parse(localStorage.user).merchantId
        : JSON.parse(localStorage.user).partner.merchantId;
    this.getPartnerId();
    this.getDefaultSetting();
    this.getBrandSettings();
  },
};
</script>
